/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        /*------------------------------------*\
            #PAGE TRANSISTION
        \*------------------------------------*/

        $(window).on('load', function () {
          $('.transition-overlay').addClass(' hide');
          $(".nano").nanoScroller();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'product': {
      init: function() {
        // JavaScript to be fired on the product page
        var $window         = $(window);
        var $body           = $('body');
        var $mainContent       = $('.main');
        var $productDetails = $('.product-details');
        var $aboutDetails   = $('.about-wrap');
        var curScrollPos    = 0;

        var bt = document.querySelectorAll('#logo')[0];
        var turbVal = { val: 0.000001 };
        var turb = document.querySelectorAll('#filter feTurbulence')[0];
        var btTl = new TimelineLite({ paused: true, onUpdate: function() {
          turb.setAttribute('baseFrequency', '0 ' + turbVal.val);
        } });

        btTl.to(turbVal, 0.3, { val: 0.15 });
        btTl.to(turbVal, 0.3, { val: 0.000001 });

        setInterval(function(){
          btTl.restart();
        }, 2500);

        /*------------------------------------*\
            #FLASHING
        \*------------------------------------*/
        $left1st = $('.hero-text .left-1st');
        $left2nd = $('.hero-text .left-2nd');
        $right1st = $('.hero-text .right-1st');
        $right2nd = $('.hero-text .right-2nd');
        $right3rd = $('.hero-text .right-3rd');
        $right4th = $('.hero-text .right-4th');

        var alltoggle;
        var leftState;
        var rightState1;
        var rightState2;
        var stayOnTimer = 0;
        var stayOnTiming = {
          left1st: 3,
          left2nd: 3.5,
          right1st: 4,
          right2nd: 4.5,
          right3rd: 5,
          right4th: 5.5,
        };

        var flashing = function () {
          stayOnTimer += 0.1;
          stayOnTimer %= 6;

          alltoggle ^= 1;
          leftState = Math.round(Math.random());
          rightState1 = Math.round(Math.random());
          rightState2  = Math.round(Math.random());
          $left1st.css({
            opacity: (leftState & alltoggle) | +(stayOnTiming.left1st < stayOnTimer),
          });
          $left2nd.css({
            opacity: ((leftState ^ 1) & alltoggle) | +(stayOnTiming.left2nd < stayOnTimer),
          });
          $right1st.css({
            opacity: (rightState1 & alltoggle) | +(stayOnTiming.right1st < stayOnTimer),
          });
          $right2nd.css({
            opacity: (rightState1 ^ 1) & alltoggle | +(stayOnTiming.right2nd < stayOnTimer),
          });
          $right3rd.css({
            opacity: (rightState2 & alltoggle) | +(stayOnTiming.right3rd < stayOnTimer),
          });
          $right4th.css({
            opacity: ((rightState2 ^ 1) & alltoggle) | +(stayOnTiming.right4th < stayOnTimer),
          });
        };

        // window.setInterval(flashing, 100);

        // Bootstrap carousel slide number
        $('.carousel').each(function(){
          var totalItems = $(this).find('.carousel-item').length;
          var currentIndex = $(this).find('div.active').index() + 1;
          $(this).find('.num').html(''+currentIndex+'/'+totalItems+'');
          $(this).on('slid.bs.carousel', function() {
            currentIndex = $(this).find('div.active').index()+1;
            $(this).find('.num').html(''+currentIndex+'/'+totalItems+'');
          });
        });

        
        $('.btn-custom--details').on('click', function(e){
          e.preventDefault();
          curScrollPos = $window.scrollTop();
          $mainContent.css({
            'transform':'translateY(' + (-curScrollPos) + 'px)',
            '-webkit-transform':'translateY(' + (-curScrollPos) + 'px)',
            '-moz-transform':'translateY(' + (-curScrollPos) + 'px)'
          });
          $productDetails.toggleClass('open');
          $body.toggleClass('details-open');
        });
        $('.close-details').on('click', function(e){
          e.preventDefault();
          $mainContent.css({
            'transform':'initial',
            '-webkit-transform':'initial',
            '-moz-transform':'initial'
          });
          $("html, body").animate({ scrollTop: curScrollPos }, '0', function() { 
            $productDetails.removeClass('open');
            $body.removeClass('details-open');
          });
        });

        $('.btn-about').on('click', function(e){
          e.preventDefault();
          curScrollPos = $window.scrollTop();
          $mainContent.css({
            'transform':'translateY(' + (-curScrollPos) + 'px)',
            '-webkit-transform':'translateY(' + (-curScrollPos) + 'px)',
            '-moz-transform':'translateY(' + (-curScrollPos) + 'px)'
          });
          $aboutDetails.addClass('open');
          $body.addClass('about-open');
        });
        $('.close-about').on('click', function(e){
          e.preventDefault();
          $mainContent.css({
            'transform':'initial',
            '-webkit-transform':'initial',
            '-moz-transform':'initial'
          });
          $("html, body").animate({ scrollTop: curScrollPos }, '0', function() { 
            $aboutDetails.removeClass('open');
            $body.removeClass('about-open');
          });
        });
        /*------------------------------------*\
            #STICKY BASKET BUTTON
        \*------------------------------------*/
        // var basketBtn;
        // var basketBtnOffsetTop;
        // var scrollTop;
        // var windowHeight;

        // function getOriBtnPos() {
        //   $basketBtn = $('.add-to-basket-btn');
        //   basketBtnOffsetTop = $basketBtn.offset().top;
        // }

        // function getWindowPos() {
        //   scrollTop = $(document).scrollTop();
        //   windowHeight = $(window).height();
        // }

        // function stickyBtn() {
        //   console.log($basketBtn.height());
        //   if (scrollTop + windowHeight - $basketBtn.height() >= basketBtnOffsetTop) {
        //     $basketBtn.addClass(' sticky');
        //   } else {
        //     $basketBtn.removeClass('sticky');
        //   }
        // }

        // getOriBtnPos();

        // $(window).on('scroll', function () {
        //   getWindowPos();
        //   stickyBtn();
        // });

        // $(window).on('resize', function () {
        //   $basketBtn.removeClass('sticky');
        //   getOriBtnPos();
        //   getWindowPos();
        //   stickyBtn();
        // });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('body').on('click', '.shop-add', function(e){
    e.preventDefault();
    var product;
    switch ($(this).attr('data-product')) {
      case "1":
        product = {
          id: 1,
          name: "This is product 1",
          quantity: 1,
          price: 100
        };
        break;
      case "2":
        product = {
          id: 2,
          name: "This is product 2",
          quantity: 1,
          price: 200
        };
        break;
    }
    $.post("/shop-ajax.php?action=addProduct", {product: product})
      .done(function( data ) {
        data = $.parseJSON(data);
        if(isOkResponse(data)) {
          updateBasket(true);
        } else {
          alert('error');
        }
      })
      .fail(function() {
        alert( "error" );
      });

    return false;
  });

  updateBasket(false);

  /**
   * Updates Basket count and flashes depending on parameter
   * @param {boolean} flash - should basket flash or not
   */
  function updateBasket(flash){
    $.get('/shop-ajax.php?action=basketCountTotal', function(data){
      data = $.parseJSON(data);
      if(isOkResponse(data)) {
        if(flash) {
          $('#basket-count').fadeOut('200', function(){
            $('#basket-count').text('('+ data.itemCount +')');
            $('#basket-count').fadeIn('200');
          });
        } else {
          $('#basket-count').text('('+ data.itemCount +')');
        }
      } else {
        alert('error');
      }
    });

  }

  /**
   * @param {json} data
   * @returns {boolean}
   */
  function isOkResponse(data) {
    return data.status == 'ok';
  }



  var resizeText = function () {
    // Standard height, for which the body font size is correct
    var preferredFontSizeHero = 115; // px
    var preferredFontSizeParagraph = 25; // px
    var preferredSize = 1366 * 956;
    //      maxScale = 180,
    var   minFullParagraph = 15; //Tweak these values to taste

    var currentSize = $(window).width() * $(window).height();
    var scalePercentage = Math.sqrt(currentSize) / Math.sqrt(preferredSize);
    var newFontSizeHero = preferredFontSizeHero * scalePercentage;
    var newFontSizeParagraph = preferredFontSizeParagraph * scalePercentage;
    //  if (newFontSize > maxScale) newFontSize = maxScale;
      if (newFontSizeParagraph < minFullParagraph) newFontSizeParagraph = minScale; //Enforce the minimum and maximums
    $(".hero-text").css("font-size", newFontSizeHero + 'px');
    $(".home-hero-text h1").css("font-size", newFontSizeHero + 'px');
    if ($(window).width() < 1336) {
      $(".two-col-layout p").css("font-size", '');
      $(".full-text-layout p").css("font-size", newFontSizeParagraph + 'px');
      $(".scale-paragraph").css("font-size", '');
    }
    else {
      $(".two-col-layout p").css("font-size", newFontSizeParagraph + 'px');
      $(".scale-paragraph").css("font-size", newFontSizeParagraph + 'px');
      $(".full-text-layout p").css("font-size", newFontSizeParagraph + 'px');

    }

  };

  $(window).bind('resize', function() {
    resizeText();
  }).trigger('resize');


    function setHeight() {
      windowHeight = $(window).innerHeight();
      if ($(window).width() > 544) {
        $('.section').css('height', windowHeight);
      } else if($(window).width() <= 544)  {
        $('.section').css('height', '');
      }
    }
  setHeight();

  $(window).resize(function() {
    setHeight();
  });





})(jQuery); // Fully reference jQuery after this point.
